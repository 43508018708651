<template>
  <footer class="footer">
    <div class="footer-contact">
      <div class="footer-contact__wrapper">
        <div class="footer-contact__row">
          <div class="footer-contact__left">
            <div class="footer-contact__img">
              <img class="lazyload" :data-src="webp(require(`../../../public/img/detail/${page}/img-product-contact.png`))" :alt="page">
            </div>
          </div>
          <div class="footer-contact__right" >
            <div class="footer-contact__info">
              <div class="footer-contact__title">
                <img :class="`lazyload ${$i18n.locale}`" :data-src="webp(require(`../../../public/img/detail/${page}/txt-contact-${$i18n.locale}.png`))" alt="koikeya contact">
              </div>
              <div class="office">
                <div class="office-title"><strong>{{ $t('contact.office.label') }}</strong></div>
                <div class="office-address" v-html="$t('contact.office.address1') + '<br>' +  $t('contact.office.address2')"></div>
                <div class="office-phone">{{ $t('contact.label.tel') }}: <a href="tel:+8402873080800">+84 (0) 287 3080 800</a></div>
              </div>
              <div class="office">
                <div class="office-title"><strong>{{ $t('contact.factory.label') }}</strong></div>
                <div class="office-address" v-html="$t('contact.factory.address')"></div>
                <div class="office-phone">{{ $t('contact.label.tel') }}: <a href="tel:+8402513681368">+84 (0) 251 368 1368</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      <div class="back-top" v-scroll-to="'#detail'">
        <!-- <img src="../../../public/img/detail/karamucho/back-to-top.png" alt=""> -->
      </div>
      <span v-html="$t('copyright')+(new Date()).getFullYear()"></span>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  props: {
    page: String
  }
}
</script>

<style lang="scss">
  @import '../../assets/scss/mixin/_mixin';
  .karamucho {
    .footer {
      &:before {
        background: url(../../../public/img/detail/karamucho/icon-footer-chili.png) no-repeat center / contain;
      }
      &-contact {
        background: linear-gradient(to right, #ffae9d 0%,#fcdab7 100%);
      }
    }
    .back-top {
      background-color: #ec3a17;

      &:hover {
        background-color: #f06d53
      }
    }
  }
  .koimucho {
    .footer {

      &:before {
        background: url(../../../public/img/detail/koimucho/icon-footer-chili.png) no-repeat center / contain;
        left: 5%;
        top: -7rem;
        width: 32rem;
      }
      &-contact {
        background: url(../../../public/img/detail/koimucho/bg-contact.jpg) no-repeat center / cover;
      }
    }
    .back-top {
      background-color: #2bb9ff;

      &:hover {
        background-color: #52c3fa;
      }
    }
  }
  .footer {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: -11rem;
      left: 28%;
      width: 20rem;
      height: 15.5rem;
      z-index: 9;

      @include media(set-max-w, 800) {
        display: none
      }
    }
    &-contact {
      padding: 16rem 0 6rem;
      
      
      @include media(set-max-w, 800) {
        padding: 5.5rem 0 3.5rem;
      }
      &__wrapper {
        max-width: 149rem;
        padding: 0 2rem;
        margin: 0 auto;
      }
      &__row {
        display: flex;
        flex-wrap: wrap;
        //margin: 0 2.5rem;

        @include media(set-max-w, 800) {
          flex-direction: column-reverse;
        }
      }
      &__left {
        @include media(set-range, 1440, 1024) {
          width: 60%;
        }
      }
      &__right {
        flex: 1;

        @include media(set-range, 1440, 1024) {
          width: 40%;
        }
        @include media(set-max-w, 800) {
          margin-bottom: 4rem;  
        }  
      }
      &__info {
        padding-top: 7rem;
        font-size: 1.6rem;
        line-height: 3rem;

        @include media(set-range, 1440, 1024) {
          padding-top: 0;
        }
        @include media(set-max-w, 800) {
          text-align: center;
          padding-top: 0;
        }  
      }
      &__title {
        margin-bottom: 3rem;
      }
    }
  }
  .office {
    &-phone {
      a {
        color: #000;
      }
    }
  }
  .copyright {
    position: relative;
    font-size: 1.6rem;
    text-align: center;
    color: #ffffff;
    padding-top: 4rem;
    padding-bottom: 3.5rem;
    background: #000000;
  }
  .back-top {
    position: absolute;
    right: 3rem;
    top: -3.5rem;
    height: 7rem;
    width: 7rem;
    z-index: 9;
    cursor: pointer;
    border-radius: 50%;
    background: url(../../../public/img/detail/karamucho/back-to-top.png) no-repeat center;
    transition: .4s ease;

    @include media(set-max-w, 800) {
      right: 1.5rem;
    }
  }

  .webp {
    .karamucho {
      .footer {
        &:before {
          background: url(../../../public/img/detail/karamucho/icon-footer-chili.webp) no-repeat center / contain;
        }
      }
    }
    .koimucho {
      .footer {
        &:before {
          background: url(../../../public/img/detail/koimucho/icon-footer-chili.webp) no-repeat center / contain;
        }
        &-contact {
          background: url(../../../public/img/detail/koimucho/bg-contact.webp) no-repeat center / cover
        }
      }
    }
    .back-top {
      background: url(../../../public/img/detail/karamucho/back-to-top.webp) no-repeat center;
    }
  }
</style>